import './App.css';
import { ThemeProvider } from 'styled-components';
import React, {useState} from 'react';
import {BrowserRouter as  Router, Route } from 'react-router-dom';
import * as ROUTES from './constants/route';
import {theme,BackgroundStyles} from './styles/globalStyles';
import HomePage from './pages/HomePage.js';

import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
function App() {
  const [isOpen, setIsOpen]=useState(false)

  const toggle=()=>{
      setIsOpen(!isOpen)
  }
  return (


    <ThemeProvider theme={theme}>
    <Router>
    <BackgroundStyles/>  
    <Switch>
     <Route exact path ={ROUTES.HOME}>
        <HomePage/>
      </Route>
      </Switch>
     </Router>
   </ThemeProvider>
  );
}

export default App;
