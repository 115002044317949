import React from 'react'
import { Heading, HeadingSpan, HeadingUnderLine } from '../PageHeading/PageHeadingElements';

function PageHeading({text}) {
    return (
        <Heading>
            {text}
            <HeadingUnderLine>
            <HeadingSpan/>
            </HeadingUnderLine>
        </Heading>
    )
}

export default PageHeading
