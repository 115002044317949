import styled from 'styled-components'


export const YouTubeCardContainer=styled.div`
height: 500px;
padding-top: 50px;
padding-bottom: 50px;
padding-left:10%;
padding-right:10%;
width: 100%;
transition: transform 100ms;
mergin-top: 10%;
margin-bottom: 20px;
&:hover{
    transform: scale(1.01);
}

`



export const YouTubeCardH2=styled.h2`
padding: 10px;
cursor: pointer;
font-weight: 200;
text-align: center;
text-transform: lowercase;
transform: transform 50ms ease-out;

&:hover{
    color:  ${props => props.theme.pureWhite};
    transform: scale(1.2);
}

color:  ${props => props.theme.slate};
line-height: 20px;
font-size: 15px;
`



