import styled,{keyframes} from 'styled-components'
import {Link as Link} from 'react-scroll'
import {Link as LinkS} from 'react-router-dom'
export const FooterContainer=styled.footer`
width: 100%;
position: relative;
bottom: 0;
background: linear-gradient(to right,  ${props => props.theme.darkBlue}, ${props => props.theme.paleOrange});
color: ${props => props.theme.slate};
padding: 100px 0 30px;
font-size: 13px;
line-height: 20px;
z-index: 1000;
`

export const FooterRow=styled.div`
width: 85%;
margin: auto;
display: flex;
flex-wrap: wrap;
align-item: flex-start;
justify-content: space-between;
`


export const FooterColumn=styled.div`
flex-basis: 20%;
padding: 10px;
`
export const FooterImg=styled.img`
width: 60%;
margin-bottom: 5px;
`

export const FooterH3=styled.h3`
width: fit-content;
margin-bottom: 40px;
position: relative;
`


export const FooterLink=styled(Link)`
display: flex;
text-decoration: none;
font-size: 14px;
text-decoration: none;
list-style: none;
color: ${props => props.theme.slate};
cursor: pointer;
transition: 0.2s ease-in-out;
margin-bottom: 10px;
&:hover{
    color:  color:${props => props.theme.orangeYellow};
}

`
export const SocialsLink=styled(LinkS)`
display: flex;
text-decoration: none;
font-size: 14px;
text-decoration: none;
list-style: none;
color: ${props => props.theme.slate};
cursor: pointer;
transition: 0.2s ease-in-out;
margin-bottom: 10px;
&:hover{
    color:${props => props.theme.orangeYellow};
}

`


export const FooterP=styled.p`
margin-bottom: 10px;
`

export const FooterLine=styled.hr`
width: 90%;
border: 0;
border-bottom: 1px solid  ${props => props.theme.lightestSlate};
margin: 20px auto;
`
export const FooterCopyright=styled.p`
text-align: center;
justify-content: center;
color: ${props => props.theme.slate};
`

export const FooterUnderLine=styled.div`
width: 100%;
height: 5px;
background: ${props => props.theme.lightNavy};

border-radius: 3px;
position: absolute;
top: 25px;
left: 0;
`


const animate = keyframes`
  0%,100% {
    transform: translateX(1px);
  }

 50% {
    transform: translateX(-10px);
  }
`;

export const FooterSpan=styled.span`
width: 10px;
height: 90%;
background: ${props => props.theme.lightestSlate};
border-radius: 3px;
position: absolute;
top: 0;
left: 10px;
 animation: ${animate} 2s linear infinite; 
`
