import React, {useState} from 'react'
import NavDesign from '../components/NavDesign/NavDesign';
import HeroSlides from '../components/HeroSlides/HeroSlides';
import DropDown from '../components/DropDown/DropDown';
import requests from '../apis/requests';
import {BrowserRouter as  Router, Route } from 'react-router-dom';
import {data} from '../fixtures/slides-data';
import Footer from '../components/Footer/Footer';
import VideoCards from '../components/VideoCards/VideoCards';
import MeetTheTeam from '../components/MeetTheTeam/MeetTheTeam';
import ContactContainer from '../components/Contact/ContactContainer';
import JumboTronContainer from '../components/JumboTron/JumboTronContainer'
import ScrollToTop from '../ScrollToTop';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
function HomePage() {

    const [isOpen, setIsOpen]=useState(false)
    const [selectedOption, setSelectedOption]=useState(requests.fetchPopular)

    const toggle=()=>{
        setIsOpen(!isOpen)
    }


    return (
        <Router> 
        <ScrollToTop/>
         <NavDesign toggle={toggle}/>
         <DropDown isOpen={isOpen} toggle={toggle}/>
         <HeroSlides slides={data}/>
         <JumboTronContainer id="about"/>
         <VideoCards id="video" selectedOption={selectedOption}/>
         <MeetTheTeam id="team"/>
         <ContactContainer id="contact"/>
         <Footer/>
        </Router>
    )
}

export default HomePage
