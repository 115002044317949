import React , { useRef}from 'react'
import {YouTubeCardContainer, YouTubeCardH2} from './YouTubeCardElements';
import ReactPlayer from 'react-player';
function YouTubeCard({image,title,id}) {
 
    var videoUrl=`https://www.youtube.com/watch?v=${id}`;
    return (

        <YouTubeCardContainer>
            <ReactPlayer controls width="100%" height="100%" url={videoUrl} poster={image} />
            <YouTubeCardH2>{title}</YouTubeCardH2>     
        </YouTubeCardContainer>
    
      
    )
}

export default YouTubeCard
