import React,{useRef,useEffect} from 'react'
import {TextSection,TextPane,Frame,Pane,Title,SubTitle,Image,Item,  ImageContainer} from './JumboTronElements'
import gsap from 'gsap';
function Jumbotron({title,direction,left,subTitle,image,alt}) {
    let titleText=useRef(null);
    let aboutText=useRef(null);
    let item=useRef(null);
    useEffect(()=>{
      
        var tl = gsap.timeline({repeat: 5, repeatDelay: 10});
        tl.to(titleText, 3,{opacity: 1,ease: "sine", duration: 1});
        tl.to(aboutText, 5,{opacity: 1,ease: "power3.inOut", duration: 1});
        tl.to(item,{x:"10%",duration:2,delay:1,ease:"Expo.easeInOut"})
        tl.to(item,{x:"-5%",duration:2,delay:1,ease:"Expo.easeInOut"})
        tl.to(item,{x:"0%",duration:2,delay:1,ease:"Expo.easeInOut"})
    },[])
   
    return (
        <>
         <Item ref={node => (item = node)} >   
         <Frame direction={direction} left={left}>
         <TextPane>
        
            <TextSection left={left}>
            <Title ref={node => (titleText = node)}  left={left}>{title}</Title>
             <SubTitle  ref={node => (aboutText = node)} left={left}>{subTitle}</SubTitle>
             </TextSection>
           
          </TextPane>
           <Pane>
               <ImageContainer>
                  <Image src={image} alt={alt}/>
               </ImageContainer>
              
           </Pane>


         </Frame>
         </Item>
        </>
     
    )
}

export default Jumbotron
