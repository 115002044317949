import styled from 'styled-components';

export const Form = styled.form`
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   transform: translate(-50%, -50%);
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
   opacity: 1.9;
  @media screen and (max-width: 600px)
  { margin-left: 40px;}
`;


export const FormLabel=styled.h1`
text-align: start;
width: 78%;
font-size: 1rem;
margin-bottom: 6px;
color:${props => props.theme.slate};
`


export const Input = styled.input`
background-color: ${props => props.theme.lightNavy};
width: 80%;
min-width: 60%;
height: ${({big})=>(big? '90px':'45px')};
border: none;
margin: ${({big})=>(big? '0.5rem 0.1rem':'0.8rem 0.1rem')};
box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
border-radius: 8px;
padding:${({big})=>(big? '0.1rem 0.5rem':'0.1rem 1rem')};
transition: all 0.2s ease-in;
resize: vertical;
&:hover {
  transform: translateY(-3px);
}
`;



export const Button=styled.button`
font-size: 1rem;
background: ${props => props.theme.lightestSlate};
border: none;
transition: 0.2s ease-out;
width: 80%;
min-width: 60%;
margin-top: 10px;
padding: 10px;
&:hover{
  transform: translateY(-4px);
  background: ${props => props.theme.orangeYellow};
}
`


export const Text=styled.p`
color: ${props => props.theme.dark};
font-size: 12px;
display: inline-block;
text-align: center;
width: 80%;
`

export const Dot=styled.div`
height: 10px;
width: 10px;
background-color: ${props => props.theme.darkOrange};
border-radius: 50%;
box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
float:left;
z-index:2;
`

export const Row=styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
`