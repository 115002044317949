import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'
import {NavLink as Link} from 'react-router-dom'

export const DropDownContainer=styled.div`
position: fixed;
z-index: 999;
width: 100%;
height: 100%;
background:${props => props.theme.lightNavy};
display: grid;
align-items: center;
top: 0;
left: 0;
transition: 0.3s ease-in-out;
opacity: ${({isOpen})=>(isOpen?1:0)};
top: ${({isOpen})=>(isOpen?'0':'-100%')};
`
export const Icon=styled.div`
position: absolute;
top: 1.2rem;
right: 1.2rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;

`
export const CloseIcon=styled(FaTimes)`
color: ${props => props.theme.lightSlate};
&:hover{
  color:  ${props => props.theme.pureWhite};
  transform: scale(1.2);
}
`
export const DropDownLink=styled(Link)`
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
font-size: 1.5rem;
text-decoration: none;
list-style: none;
color: ${props => props.theme.lightSlate};
cursor: pointer;
transition: 0.2s ease-in-out;
&:hover{
  color:  ${props => props.theme.pureWhite};
  transform: scale(1.2);
}

`
export const DropDownWrapper=styled.div`
`

export const DropDownMenu=styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(4,80px);
text-align: center;
margin-bottom: 4rem;

@ media screen & max-width(480px)
{
    grid-template-rows: repeat(4,60px);
}
`

export const ButtonWrap=styled.div`
display: flex;
justify-content: center;
`


