import React from 'react'
import { Card, CardContainer, CardContent, CardH2, CardImage, CardSpan, ImageContainer } from './CardDesignThreeElements'

function CardDesignThree({image,title,description,inputColor,textColor}) {
    return (
        <CardContainer>
        <Card inputColor={inputColor}>

           <ImageContainer>
               <CardImage src={image}>
               </CardImage>
           </ImageContainer>

             <CardContent>
               <CardH2 textColor={textColor}>

                   {title}
              
               </CardH2>
                 <br/>
               <CardSpan textColor={textColor}>{description}</CardSpan>
             </CardContent>
         
         
         </Card>


     </CardContainer>
    )
}

export default CardDesignThree
