import styled,{keyframes} from 'styled-components';


export const Heading=styled.h1`
font-size: clamp(20px,4.0vw,44px);
text-align: center;
justify-content: center;
margin: 10px;
text-transform: uppercase;
text-decoration: underline;
background: ${props => props.theme.slate};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
opacity: 0.8;
padding: 60px 0;
&:hover{
  background: ${props => props.theme.orangeYellow};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
}
`


export const  HeadingUnderLine=styled.div`
width: 50%;
height: 5px;
background: ${props => props.theme.lightNavy};
border-radius: 3px;
position: relative;
top: 25px;
left: 25%;
`


const animate = keyframes`
  0%,100% {
    transform: translateX(50%);
  }

 50% {
    transform: translateX(-5%);
  }
`;

export const  HeadingSpan=styled.span`
width: 60%;
height: 90%;
background: ${props => props.theme.lightSlate};
border-radius: 3px;
position: absolute;
top: 0;
left: 10px;
animation: ${animate} 10s linear infinite; 
&:hover{
  background: ${props => props.theme.orangeYellow};
  animation: ${animate} 10s linear infinite;
}
`
