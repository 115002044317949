export const data = [
  
    {
      "_id": "id3",
      "image": "https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/gallery/PHOTO-2020-11-30-11-04-58.jpg",
      "title": "The Vee Show",
      "description": "Lets talk about health",
      "link":"https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ"
    }, {
      "_id": "id4",
      "image": "https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/gallery/PHOTO-2020-11-30-11-04-58_1.jpg",
      "title": "The Vee Show",
      "description": "Relationships and more",
      "link":"https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ"
    },
    {
      "_id": "id6",
      "image": "https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/gallery/PHOTO-2020-11-30-11-04-59.jpg",
      "title": "The Vee Show",
      "description": "New videos every thursday at 4pm and sunday at 2pm",
      "link":"https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ"
    }, {
      "_id": "id7",
      "image": "https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/gallery/PHOTO-2020-11-30-11-04-55.jpg",
      "title": "The Vee Show",
      "description": "Subscribe to the vee show on you tube",
      "link":"https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ"
    }
  ,
  {
    "_id": "id8",
    "image": " https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/nobg/PHOTO-2020-11-30-11-04-55_1-removebg-preview.png",
    "title": "The Vee Show",
    "description": "Subscribe to the vee show on you tube",
    "link":"https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ"
  }, {
    "_id": "id9",
    "image": "https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/nobg/%5Bremoval.ai%5D_tmp-60fd59202faf0.png",
    "title": "The Vee Show",
    "description": "Subscribe to the vee show on you tube",
    "link":"https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ"
  }

  
  
  
  ]







