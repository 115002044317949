import React from 'react'
import { Nav,Logo,MenuBars,NavMenu,NavMenuLinks,NavBtn, LogoImg } from './NavDesignElements'
import menudata  from  '../../fixtures/menu-data.json'
import {Button} from '../Button/ButtonElements';
import Scroll from 'react-scroll'

function NavDesignThree({toggle}) {
    const ScrollLink = Scroll.ScrollLink

    return (

        <Nav>
            <Logo to="https://www.youtube.com/channel/UCGGlNAoeiGhf6fBSIckuJeQ" target="_blank">
                <LogoImg src="https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/gallery/logo-removebg-preview.png"/></Logo>
            <MenuBars onClick={toggle}/>
            <NavMenu>
             

            { menudata.map((item,index)=>{
                
                return(
                    <NavMenuLinks key={item.id} 
                      to={item.link} 
                      activeClass='active'
                      spy={true}
                      smooth={true} 
                      duration={500}>
                        {item.name}
                    </NavMenuLinks> 
                  )
                  } ) 
                  
             } 
    
                  <NavBtn>
                    <Button to="contact" primary='true'> Contact</Button>
                  </NavBtn>
                 
             </NavMenu>   
        </Nav>
    )
}



export default NavDesignThree
