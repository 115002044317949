import React from 'react'
import {Container,LeftContent,RightContent} from './ContactContainerElements';
import Contact from './ContactForm';
import PageHeading from '../PageHeading/PageHeading';
import SocialDesignOne from '../SocialDesignOne/SocialDesignOne';
function ContactContainer({id}) {
    return (
         
         <div id={id}>
         <PageHeading text="Get In Touch"/>
        <Container>
       
            <LeftContent>
            <SocialDesignOne/> 
            </LeftContent> 

           
            <RightContent>
              <Contact/>  
            </RightContent>
         

        </Container>
        </div>
    
      
    )
}

export default ContactContainer
