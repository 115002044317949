import axios from 'axios';

const { REACT_APP_YOUTUBE_KEY,REACT_APP_YOUTUBE_CHANNELID,REACT_APP_YOUTUBE_BASEURL} = process.env;


export default axios.create({
    baseURL: REACT_APP_YOUTUBE_BASEURL,
    params: {
        part: 'snippet',
        channelId:  REACT_APP_YOUTUBE_CHANNELID,
        key:  REACT_APP_YOUTUBE_KEY,
        maxResults: 8
    }
})