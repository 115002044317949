import styled ,{css} from 'styled-components/macro'
import {NavLink as Link} from 'react-router-dom'
import { FaFacebookF, FaLinkedinIn,FaSkype,FaWhatsapp,FaTwitter,
    FaYoutube,FaInstagram,FaGooglePlusG} from "react-icons/fa";

export const Section = styled.section`
position: relative;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
`

export const Container=styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
display:flex;
width: 80%;
margin: 0;
padding: 0;
`


export const NavLink=css`
margin: 20px 20px;
background:${props => props.theme.lightNavy};
text-decoration: none;
display: flex;
height: 80px;
text-align: left;
padding: 10px;
align-items: center;
cursor: pointer;
transform: rotate(-30deg) skew(25deg) translate(0,0);
font-family: 'Montserrat',sans-serif;
transition: 0.5s;
box-shadow: -20px 20px 10px rgba(0,0,0,0.5);

@media screen and (max-width: 650px)
{
    width: 80px;

}

&:hover{
    transform: rotate(-30deg) skew(25deg) translate(20px,-15px);
    box-shadow: -50px 50px 50px rgba(0,0,0,0.5);
    background:  ${props => props.inputcolor || "#ff0"};
    &:after,
    &:before{
        background:  ${props => props.inputcolor || "#ff0"};
    } 
}

&:before{
    content: '';
    position: absolute;
    top: 10px;
    left: -20px;
    width: 20px;
    height: 100%;
    transform: 0.5s;
    transform: rotate(0deg) skewY(-45deg);
    background: linear-gradient(${props => props.theme.lightestNavy},${props => props.theme.slate});    
}



&:after{
    content: '';
    position: absolute;
    top: 80px;
    right: 10px;
    width: 100%;
    height: 20px;
    transform: 0.5s;
    transform: rotate(0deg) skewX(-45deg);
    background: linear-gradient(${props => props.theme.lightest},${props => props.theme.slate}); 
}


`

export const SocialLink = styled(Link)`
${NavLink}
font-style: italic;

`

export const Socials=css`
font-size: 32px;
color:${props => props.theme.slate};
line-height: 80px;
padding: 5px;
transition: 0.5s;
transform: translate(-50%,-15%);
`
export const SocialSpan=styled.span`
padding: 0;
margin: 0;
position: relative;
top: 5px;
left: 5px;
color: ${props => props.theme.slate};
letter-spacing: 2px;
transition: 0.5s;
width: 100%;
display: none;
`





export const FaceBookIcon= styled(FaFacebookF)`
${Socials}
`
export const GoogleIcon= styled(FaGooglePlusG)`
${Socials}
`

export const InstagramIcon= styled(FaInstagram)`
${Socials}
`

export const WhatsappIcon= styled(FaWhatsapp)`
${Socials}
`
