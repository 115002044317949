import React from 'react'
import Slide from 'react-reveal/Slide';
import { Section,Container,SocialLink,FaceBookIcon,
    InstagramIcon,WhatsappIcon,GoogleIcon, SocialSpan } from './SocialDesignOneElements'

function SocialDesignOne({id}) {
    return (

        <Section id={id}>
          
            <Container>
            <Slide left>
            <SocialLink  inputcolor="#1877f2" to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >
            <FaceBookIcon/>
            <SocialSpan>Facebook </SocialSpan>
           </SocialLink> 

           <SocialLink  inputcolor="#f46f30" to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >
            <GoogleIcon/>
            <SocialSpan>Google </SocialSpan>
           </SocialLink> 

           <SocialLink  inputcolor="#fbbc05" to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >
            <InstagramIcon/>
            <SocialSpan>Instagram </SocialSpan>
           </SocialLink> 
           
           <SocialLink  inputcolor="#25d366" to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >
            <WhatsappIcon/>
            <SocialSpan>Whatsapp </SocialSpan>
            </SocialLink> 

            <SocialLink  inputcolor="#1877f2" to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >
            <FaceBookIcon/>
            <SocialSpan>Face </SocialSpan>
            <SocialSpan>Book </SocialSpan>
           </SocialLink> 

         

          
            </Slide>
           </Container>
           
        </Section>
    )
}

export default SocialDesignOne
