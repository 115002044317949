import React from 'react'
import Jumbotron from './Jumbotron'
import {Container} from './JumboTronContainerElements'
import jumboData from '../../fixtures/jumbo.json'
import PageHeading from '../PageHeading/PageHeading'
function JumboTronContainer({id}) {
    return (
        <div id={id}>
        <PageHeading text="About us"/>
        <Container>
            
            {
            jumboData.map((item) => (
                   <Jumbotron key={item.id} 
                   direction={item.direction} 
                   left={item.left}
                   title={item.title}
                   subTitle={item.subTitle}
                   image={item.image}
                   alt={item.alt}
            /> ))
            }
        </Container>
        </div>
    )
}

export default JumboTronContainer
