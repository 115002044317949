import styled ,{css} from 'styled-components/macro'
import {NavLink as Link} from 'react-router-dom'
import {IoMdArrowRoundForward} from 'react-icons/io'
import {IoArrowForward,IoArrowBack} from 'react-icons/io5'

export const HeroSection = styled.section `
height: 100vh;
max-height: 1100px;
position: relative;
overflow: hidden;
`
export const HeroWrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
position: relative;
`
export const HeroSlide= styled.div`
z-index: 1;
width: 100%;
height: 100%;
opacity:1.75;
`
export const HeroSlider= styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%
display: flex;
align-items: center;
justify-content: center;
z-index: 2;
&::before{
  content: '';
 position: absolute;
 z-index: 2;
 width: 100%
 height: 100vh;
 bottom: 0vh;
left: 0;
overflow: hidden;
opacity: 0.4;
background: linear-gradient(
  0deg,
  rgba(0,0,0,0.2) 0%,
  rgba(0,0,0,0.2) 50%,
  rgba(0,0,0,0.6) 100% );

}
`

export const HeroContent= styled.div`
position: relative;
display: flex;
margin-top: 200px;
margin-bottom: 20px;
flex-direction: column;
max-width: 1600px;
z-index: 50000;
justify-content: center;
align-items: center;
width: calc(100%-100px);
color:  ${props => props.theme.pureWhite};
`


export const HeroH1=styled.h1`
font-size: clamp(1rem,8vw,2rem);
font-weight: 400;
text-transform: uppercase;
text-shadow: 0px 0px 20px rgba(0,0,0,0.4);
text-align: center;
margin: 30px;
color:  ${props => props.theme.pureWhite};
`
export const HeroP=styled.p`
color:  ${props => props.theme.pureWhite};
margin-bottom: 10px;
`
export const HeroButton=styled(Link)`
white-space: nowrap;
outline: none;
border: none;
min-width: 100px;
max-width: 160px;
cursor: pointer;
text-decoration: none;
transition:0.3s;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
margin: 20px;
background: ${({primary})=>(primary? '#000d1a':'C0853F')};
color: ${({primary})=>(primary? '#fff':'#000d1a')};
padding: ${({big})=>(big? '16px 40px':'14px 24px')};
font-size: ${({big})=>(big? '20px':'14px')};

&:hover{
  transform: translateY(-2px);
}
`



export const HeroImage= styled.img`
position: relative;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
object-fit: cover;
align-items: center;
justify-content: center;
object-position: 88% 12%;
opacity: 0.8;
z-index: -10;
`

export const HeroArrow=styled(IoMdArrowRoundForward)`
margin-left: 0.5rem;
color:  ${props => props.theme.slate};

`
export const SliderButtons=styled.div`
position: absolute;
bottom: 50px;
right: 100px;
display: flex;
z-index: 10;

`
const arrowButtons=css`
width: 40px;
height:40px;
color:  ${props => props.theme.slate};
cursor: pointer;
background: #000d1a;
border-radius: 50px;
padding: 10px;
margin-right: 1rem;
user-select: none;
transition: 0.3s;
&:hover{
    background:  ${props => props.theme.lightNavy};
    transform: scale(1.05);
  }


`
export const PrevArrow=styled(IoArrowBack)`
${arrowButtons}
`
export const NextArrow=styled(IoArrowForward)`
${arrowButtons}
`