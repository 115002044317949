import styled ,{css} from 'styled-components/macro';
import {Link as LinkS} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';
import {FaBars} from 'react-icons/fa';

export const Nav =styled.nav`
height: 50px;
display: flex;
padding: 1rem 2rem;
z-index: 100;
position: fixed;
width: 100%;
justify-content: space-between;
opacity: ${({isOpen})=>(isOpen? '0.4':'1')};
font-weight: 700;
`;

export const NavLink=css`
color: ${props => props.theme.darkWhite};
padding: 10px 20px;
display: flex;
align-items: center;
height: 100%;
cursor: pointer;
text-decoration: none;
&:active{
  border-bottom: 3px solid ${props => props.theme.orangeYellow};
}

`

export const Logo = styled(LinkR)`
${NavLink}
font-style: italic;
`;
export const LogoImg=styled.img`
padding: 10px;
width: 25%;
margin: 10px;
`

export const MenuBars=styled(FaBars)`
display: none;
@media screen and (max-width: 768px)
{  display: block;
   cursor: pointer;
   transform: translate(-5%,25%);
   color:${props => props.theme.slate};
   width:40px;
   height: 20px;
}
`

export const NavMenu=styled.div`
display: flex;
align-items: center;
margin-right: -48px;

@media screen and (max-width: 768px)
{  display: none;

}

`

export const NavMenuLinks=styled(LinkS)`
${NavLink}
`

export const NavBtn=styled.div`
display: flex;
align-items: center;
margin-right: 24px;
`


