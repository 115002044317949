import styled from 'styled-components';



export const Container=styled.div`
position: relative;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 2px solid ${props => props.theme.darkNavy};
overflow: hidden;
height: auto;
padding: 80px calc((100vw-1300px)/2);
color: ${props => props.theme.plainWhite};
@media screen and (max-width: 650px)
{
   flex-direction: column;

}

`
