import styled from 'styled-components';

export const CardContainer=styled.div`
max-width: 94%;
margin: 100px auto;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
`

export const Card=styled.div`
position: relative;
width: 250px;
height: 340px;
background: ${props => props.theme.lightNavy};
margin: 0 auto;
border-radius: 4px;
opacity: 0.8;
box-shadow: 1px 6px 4px  ${props => props.theme.lightestNavy};
z-index: 1;

&:before,
&:after{
    content: '';
    position: absolute;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: ${props => props.theme.lightNavy};
    transition: 0.5s;
    z-index: -2;
   
}

&:hover
{ 
    bottom: 80px;
    &:before{
        z-index: -1;
   transform: rotate(15deg);
   }
   &:after{
    transform: rotate(25deg);
    z-index: -1;

   }

}


`

export const ImageContainer=styled.div`
position: absolute;
top: 10px;
left:10px;
bottom: 10px;
right: 10px;
background: ${props => props.theme.lightNavy};
transition: 0.5s;
z-index: 1;
&:hover{
    bottom: 80px;
    height: 70%;
}
`

export const CardImage=styled.img`
position: absolute;
top: 10px;
left:10px;
width: 100%;
height: 100%;
object-fit: contain;
`


export const CardContent=styled.div`
position: absolute;
left: 10px;
right: 10px;
bottom: 10px;
height: 60px;
text-algin: center;
`

export const CardH2=styled.h2`
margin: 0;
padding: 0;
font-weight: 600;
font-size: 20px;
color: ${props => props.theme.darkWhite};
text-transform: uppercase;
`

export const CardSpan=styled.span`
font-weight: 500;
font-size: 16px;
color: #FFF5EE;
display: block;
margin-top: -10px;
`

