import {createGlobalStyle} from 'styled-components'





export const theme = {
    primary: '#ff0198',
    secondary: '#01c1d6',
    danger: '#eb238e',
    light: '#f4f4f4',
    dark: '#222',
    darkNavy: '#000030',
    navy: '#0a192f',
    lightNavy: '#112240',
    lightestNavy: '#233554',
    orange:'#FF5200',
    darkOrange:'#BB371A',
    lightOrange:'#F45905',
    orangeYellow:'#FFBF00',
    pureWhite: '#fff',
    plainWhite:'#FDFDFD',
    darkWhite:'#FFF5EE',
    creamyWhite:'#FEFFDB',
    yellow:'#FFCD00',
    lightYellow:'#FEFF89',
    slate: '#8892b0',
    lightSlate: '#a8b2d1',
    lightestSlate: '#ccd6f6',
    darkBlue:'#00093c',
    paleOrange: '#2d0b00'
  }
  
  
export const BackgroundStyles = createGlobalStyle`
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Alegreya', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020c1b;
  color: #333333;
  font-size: 16px;
  scrollbar-color: dark;
  scrollbar-width: 5px;
  &::-webkit-scrollbar{
    width: 5px;
    color: #fff;
    
  }
}`;

export const TestStyles = createGlobalStyle`
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Alegreya', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020c1b;
  color: #333333;
  font-size: 16px;
  scrollbar-color: dark;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overlow-style: none;
  scrollbar-width: none;
}`;