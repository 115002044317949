import React,{useEffect,useState,useRef} from 'react'
import { HeroSection,HeroWrapper,
         HeroSlide,HeroSlider,HeroH1,HeroImage,HeroContent,HeroP,
         HeroButton,HeroArrow,SliderButtons,PrevArrow,NextArrow} from './HeroSlidesElements';
         import {Button} from '../Button/ButtonElements';

function HeroSlides({slides}) {
    
    const [current,setCurrent]=useState(0)
    const length=slides.length;
    const timeout=useRef(null);

    useEffect(() => {
        
        const nextSlide =() => {
            setCurrent(current=== length-1? 0: current+1);
        }
        timeout.current=setTimeout(nextSlide,2000)
        return function(){
            if(timeout.current){
                clearTimeout(timeout.current);
            }
        };

    }, [current,length])

    const nextSlide=()=>{
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setCurrent(current=== length-1? 0: current+1);
        
    
    }

    const prevSlide=()=>{
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setCurrent(current=== 0? length-1: current-1);
        
     }
  
  
    if(!Array.isArray(slides) || slides.length<=0)
    {
        return null;
    }
  
  
  
  
    return (
        <HeroSection>
            
            <HeroWrapper >

        
           {
           
           slides.map((slide,index)=>{
            return(

             <HeroSlide key={slide._id}>
                 {index === current && (
                   <HeroSlider>
                   <HeroImage src={slide.image}/>
                  
                   <HeroContent>
                    <HeroH1>{slide.title}</HeroH1>
                    <HeroP primary='true' big='true'>{slide.description} </HeroP>
                   
                    <Button to={{pathname: slide.link}} target="_blank" big='true' 
                     primary='false'
                     css={` max-width: 160px;`} >

                         Watch now
                    
                     <HeroArrow/>
                     </Button>
                   
                    

                   </HeroContent>

                   </HeroSlider>



                 )}
               
              </HeroSlide>
              )
              } ) }


              <SliderButtons>
                  
                  <PrevArrow onClick={prevSlide}/>
                   <NextArrow onClick={nextSlide}/>
              </SliderButtons>


            </HeroWrapper>
            
        </HeroSection>
    )
}

export default HeroSlides
