import React from 'react'
import data from '../../fixtures/team.json'
import CardDesignThree from '../CardDesignThree/CardDesignThree';
import PageHeading from '../PageHeading/PageHeading';
import {Container } from './MeetTheTeamElements';
import Slide from 'react-reveal/Slide';
function MeetTheTeam({id}) {
    return (
      <div id={id}>
          <PageHeading text="Meet The Team"/>
        <Container>
            <Slide right>
       {
            data.map(item=>
        <CardDesignThree key={item._id} image={item.image}
        title={item.title} description={item.description} />

            )
        }
           </Slide> 
        </Container>
        </div>
    )
}

export default MeetTheTeam
