import React from 'react'
import {DropDownContainer,DropDownWrapper,DropDownMenu,DropDownLink,
    Icon,CloseIcon,ButtonWrap} from './DropDownElements';
import {Button} from '../Button/ButtonElements';
import menudata from '../../fixtures/menu-data.json'

function DropDown({isOpen,toggle}) {
    return (
        <DropDownContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}><CloseIcon/></Icon>.
          <DropDownWrapper>
          <DropDownMenu>
          { menudata.map((item,index)=>{
            return(
                <DropDownLink key={item.id} to={item.link}>
                    {item.name}
                </DropDownLink> 
              )
              } ) 
              
              } 
          </DropDownMenu>

          <ButtonWrap>
              <Button primary="true" round="true" big="true" to="/contact">Contact Us</Button>
          </ButtonWrap>


         </DropDownWrapper>
        
        </DropDownContainer>
    )
}

export default DropDown
