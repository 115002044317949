import styled from 'styled-components'



export const Frame=styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: ${({ direction }) => direction};
max-width: 1100px;
margin: auto-100vh;
width: 100%;
@media (max-width: 1000px) {
  flex-direction: column;
}
`

export const Pane=styled.div`
width: 50%;
padding:10px 10px 15px 20px;
@media (max-width: 1000px) {
  width: 100%;
  padding: 0 45px;
  text-align: center;
}
`
export const TextPane=styled.div`
width: 50%;
padding:20px 20px 25px 30px;
@media (max-width: 1000px) {
  width: 100%;
  padding: 0 45px;
  text-align: center;
}
`

export const TextSection=styled.div`
position: relative;
z-index:9;
&:before{
    content: '';
    position: absolute;
    top: 8px;
    left: ${({left})=> (left? '-10%':'110%')};
    width: 1px;
    height: 100%;
    background: linear-gradient(to top,transparent,${props => props.theme.slate},transparent);  
}
@media (max-width: 468px) {
  left: 0px;
  right:0px;
  &:before{
    left: -30px;
    right:0px;
  }
}
`

export const Title=styled.h1`
opacity: 0;
font-size: 40px;
line-height: 1.1;
margin-bottom: 8px;
text-align: ${({left})=> (left? 'right':'left')};
@media (max-width: 968px) {
  text-align: ${({left})=> (left? 'left':'left')};
}
@media (max-width: 650px) {
  font-size: 25px;
}
`

export const SubTitle=styled.h2`
 opacity: 0;
 font-size: 20px;
 font-weight: normal;
 line-height:30px;
 text-align: ${({left})=> (left? 'right':'left')};
 @media (max-width: 968px) {
  text-align: ${({left})=> (left? 'left':'left')};
}
@media (max-width: 650px) {
  font-size: 14px;
}
`
export const ImageContainer=styled.div`
position: relative;

`
export const Image=styled.img`
max-width: auto;
height: auto;
min-width: 300px;
max-width: 80%;
`

export const Item=styled.div`
display: flex;
padding: 50px 5%;
color:${props => props.theme.slate};
overflow: hidden;
`

export const Animation=styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100vh;
z-index: 2;
background-color: linear-gradient(to top,transparent,${props => props.theme.slate},transparent);  

`



