import React from 'react';
import {Form,Input,Button,FormLabel,Text,Dot,Row} from './ContactFormElements';
import { useForm } from "react-hook-form";
function Contact() {


  const { register, handleSubmit, formState: { errors },reset } = useForm();
  const onSubmit=data=>{
    console.log(data);
    reset({
        name:'',
        email:''
      });
   
}
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
        <FormLabel>Name</FormLabel>
        <Row>
        {errors.name ? <Dot/>:<div/>}
        <Input type="text"  id="name"  aria-invalid={errors.name ? "true" : "false"} {...register("name", {required: true, maxLength: 150})} />
        </Row>

        <FormLabel>Email</FormLabel>
        <Row>
        {errors.email ? <Dot/>:<div/>}
        <Input type="text" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
        </Row>

      
       <FormLabel>Number</FormLabel>
        <Row>
        {errors.email ? <Dot/>:<div/>}
        <Input type="tel"  {...register("number", {required: true, minLength: 6, maxLength: 13})} />
        </Row>

      
       <FormLabel>Message</FormLabel>
       <Row>
        {errors.message ? <Dot/>:<div/>}
        <Input big="true" type="text"  {...register("message", {required: true, maxLength: 800})} />
        </Row>
      
      <Button type="submit"> Submit</Button> 
     
      
    </Form>
    
  );

 
   
}

export default Contact












