import React from 'react'
import { FooterContainer,FooterColumn,FooterImg,FooterP,FooterRow,FooterLink,FooterLocation,SocialsLink,FooterH3, FooterCopyright, FooterLine, FooterUnderLine, FooterSpan } from './FooterElements'
import menudata from '../../fixtures/menu-data.json'
import socialsdata from '../../fixtures/socials-data.json'
function Footer() {
    return (
        <FooterContainer>
            <FooterRow>

                <FooterColumn>
                    <FooterImg src="https://reactappstutorials.s3.us-east-2.amazonaws.com/veeshow/gallery/logo-removebg-preview.png"></FooterImg>
                    <FooterP>Subscribe to the Vee show channel to watch more entertaining videos. 
                        Press the bell icon to get immediate notification of our latest videos
                    </FooterP>
                </FooterColumn>

                <FooterColumn>
                    <FooterH3>Office<FooterUnderLine>
                       <FooterSpan/>
                       </FooterUnderLine>
                       </FooterH3>
                    <FooterP>23 Adum Road, Kumasi </FooterP>
                    <FooterP>Old top man building </FooterP>
                    <FooterP>2nd Floor </FooterP>
                </FooterColumn>

                <FooterColumn>
                    <FooterH3>Links
                       <FooterUnderLine>
                       <FooterSpan/>
                       </FooterUnderLine>

                    </FooterH3>
                { menudata.map((item,index)=>{
                return(
                <FooterLink key={item.id} to={item.link}>
                    {item.name}
                </FooterLink>)})} 
                </FooterColumn>

                <FooterColumn>
                    <FooterH3>Socials
                       <FooterUnderLine>
                       <FooterSpan/>
                       </FooterUnderLine>

                    </FooterH3>
                { socialsdata.map((item,index)=>{
                return(
                <SocialsLink key={item.id}  to={{pathname: item.link}} target="_blank">
                    {item.name}
                </SocialsLink>)})} 
                </FooterColumn>

            </FooterRow>
            <FooterLine/>
            <FooterCopyright>Vee Show GH  ©  2021 - All Rights Reserved </FooterCopyright>
        </FooterContainer>
    )
}

export default Footer
