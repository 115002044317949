import React, { useState,useEffect } from 'react'
import { Container } from './VideoCardsElements'
import YouTubeCard from '../YouTubeCard/YouTubeCard';
import axios from '../../apis/youtube';
import PageHeading from '../PageHeading/PageHeading';
function VideoCards({selectedOption,id}) {
    const [videos, setVideos]=useState([]);

    useEffect(()=>{
         async function fetchData(){
             const request=await axios.get(selectedOption);
             console.log(request.data.items);
             setVideos(request.data.items);
             return request;

         }     

         fetchData();

    },[selectedOption])

    const getId=(video,index)=>{
        if(video.id.videoId)
         {
            console.log("Get Video Id "+video.id.videoId);
            return video.id.videoId; 
           
         } 
       
        else if(video.snippet.resourceId)
        {  
            console.log("Resource id "+video.snippet.resourceId.videoId);
            return video.snippet.resourceId.videoId;
        }
        else{
                return null;
                
         }

             
        
    
    }
   
    return (
        <Container id={id}>
           <PageHeading text="Featured Videos"/>
           
            { videos.map((video,index)=>{
                  const videoId=getId(video,index);
                  if (videoId) {
                    return (
                      
                    <YouTubeCard key={videoId}
                     id={videoId} 
                    image={video.snippet.thumbnails.high.url} 
                    title={video.snippet.title}
                    />
                  )
                  }
                  return <></>
          
              } )
              
              } 
            
        </Container>
            
    
    )
}

export default VideoCards
