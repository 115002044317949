import styled from 'styled-components';

export const Container = styled.div`
margin: 10px;
width: 100%;
height: 100vh;
position relative;
border-radius: 5px;
display: grid;
grid-template-columns: 1fr 1fr;
`;


export const LeftContent = styled.div`
z-index: 2;
  @media screen and (max-width: 600px)
 {  display: none;}
`;

export const RightContent = styled.div`  
  margin:10px;
  border-radius: 10px 0 0 10px;
  position: relative;
  @media screen and (max-width: 600px)
  { width: 100%;}
`;

