const MAXRESULTS=8;
const PAGETOKEN="";

export default{
 fetchAll: `search?`,
 fetchLatest:`search?&order=date`,
 fetchAllNext:`search?&pageToken=${PAGETOKEN}`,
 fetchPopular: `search?&chart=mostPopular`,
 fetchTopFive: `search?&chart=mostPopular&maxResults=${MAXRESULTS}`,
 fetchInterviewPlaylist:`playlistItems?&playlistId=PLBEj-Ajk_JYpOUV2jUymQWQqBDvy8ujWc`,
 fetchHealthPlaylist:`playlistItems?&playlistId=PLBEj-Ajk_JYr1eL3KgX986YIpMrMh52mZ`,
 fetchEntertainmentPlaylist:`playlistItems?&playlistId=PLBEj-Ajk_JYpOUV2jUymQWQqBDvy8ujWc`,
 fetchRelationshipPlaylist:`playlistItems?&playlistId=PLBEj-Ajk_JYrKgfHiSTeqEzIFhuc1Q6qa`,
 fetchFashionPlaylist:`playlistItems?&playlistId=PLBEj-Ajk_JYqwyGdhbn-n3r4vV3y0J-3W`,
 fetchPopularNext:`search?&chart=mostPopular&pageToken=${PAGETOKEN}`
}

